import React, { useState, useEffect } from "react";

import style from "../css/LogoSplash.css";

const LogoSplash = () => {
  // fontSize = 450 / Math.round(0.2 * scrollPosition + 10);
  //style={{ postion: -100, fontSize: fontSize.toString() + "rem" }}
  return (
    <div className="LogoSplash">
      <div className="title Z">
        Z <div className="title innerLogo">Score</div>
        <div className="box"></div>
      </div>
      <div className="slogan">
        <h2>
          Connecting Fortune 1000 companies to teams of top-notch offshore,
          near-shore, and onshore software developers.
        </h2>
      </div>
    </div>
  );
};

export default LogoSplash;
