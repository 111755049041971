import "./App.css";
import { useRef } from "react";
import Header from "./components/Header";
import LogoSplash from "./components/LogoSplash";
import About from "./components/About";
import ValueProp from "./components/ValueProp";
import Portfolio from "./components/Portfolio";
import SetAppointment from "./components/SetAppointment";
import Footer from "./components/Footer";

import Contact from "./components/Contact";

function App() {
  const aboutRef = useRef();
  const valuePropRef = useRef();
  const portfolioRef = useRef();
  const setAppointmentRef = useRef();
  const contactRef = useRef();

  return (
    <div className="App">
      <Header
        aboutRef={aboutRef}
        valuePropRef={valuePropRef}
        portfolioRef={portfolioRef}
        setAppointmentRef={setAppointmentRef}
        contactRef={contactRef}
      />
      <div className="outerblock">
        <LogoSplash />

        <div ref={aboutRef}></div>
        <About setAppointmentRef={setAppointmentRef} contactRef={contactRef} />
        <div ref={valuePropRef}></div>
        <ValueProp />
        <div ref={portfolioRef}></div>
        <Portfolio
          setAppointmentRef={setAppointmentRef}
          contactRef={contactRef}
        />
        <div ref={contactRef}></div>
        <Contact />
        <div ref={setAppointmentRef}></div>
        <SetAppointment />
      </div>
      <Footer />
    </div>
  );
}

export default App;
