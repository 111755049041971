import React, { useState, useEffect } from "react";
import { InlineWidget } from "react-calendly";

const SetAppointment = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 750);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 750);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <div className="setAppointment">
      <h1 style={{ marginBottom: "-50px" }}>Set an Appointment</h1>
      <div className="calendly-wrapper">
        <InlineWidget
          styles={{ height: isDesktop ? "740px" : "550px" }}
          url="https://calendly.com/zscoreoffshore"
        />
      </div>
    </div>
  );
};

export default SetAppointment;
