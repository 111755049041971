// import * as React from "react";
import React, { useState, useEffect } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ControlledAccordions({
  contactRef,
  setAppointmentRef
}) {
  const scrollToRef = reff => {
    reff.current.scrollIntoView({ behavior: "smooth" });
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const skills = [
    "Adobe",
    "Artificial Intelligence & Machine Learning",
    "Augmented Reality / Virtual Reality",
    "AWS",
    "Azure",
    "Blockchain & Smart Contracts",
    "Business Analysis",
    "Business Intelligence",
    "Content Management Solution",
    "Custom ERP",
    "Cybersecurity",
    "Data Science & Analytics",
    "DevOps",
    "Digital Ocean",
    "E-Commerce",
    "Google Cloud Platform",
    "Java",
    "JavaScript",
    "Learning Management Systems",
    "MEAN / MERN",
    "Microsoft",
    "Mobile App Dev (iOS / Android)",
    "On-Prem to Hybrid / Cloud Deployments",
    "PHP",
    "Project Management",
    "Quality Assurance",
    "UI / UX"
  ];

  const resources = [
    "Atlassian",
    "AWS",
    "Certified Ethical Hacker",
    "Google",
    "Microsoft",
    "Moodle",
    "nopCommerce",
    "Odoo",
    "Oracle",
    "PMP",
    "Salesforce"
  ];

  const industries = [
    "Automotive",
    "Education / EdTech",
    "Fintech",
    "Garments",
    "Government",
    "Healthcare / Pharma",
    "Non-profit",
    "Real Estate",
    "Retail",
    "Software",
    "Startups",
    "Telecom"
  ];

  const list_skills = () => {
    for (let skill in skills) {
      return <ul>skills[skill]</ul>;
    }
  };
  return (
    <div className="portfolio">
      <h1> Portfolio </h1>
      <p>
        Our developers are fully vetted, affordable, global, and have superior
        domain knowledge. A large proportion of them have worked with enterprise
        clients in the USA and Europe. The following are areas where we have
        resources available to join ongoing projects:
      </p>
      <div className="portfolioList">
        <Accordion style={{ backgroundColor: "var(--bg)" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3 style={{ margin: 0 }}>Skillsets & Stacks</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {skills.map(s => {
                return <li>{s}</li>;
              })}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "var(--bg)" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3 style={{ margin: 0 }}>Certified Resources</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {resources.map(r => {
                return <li>{r}</li>;
              })}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ backgroundColor: "var(--bg)" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3 style={{ margin: 0 }}>Industries Represented</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {industries.map(i => {
                return <li>{i}</li>;
              })}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <p className="CTA" style={{ marginTop: "20px" }}>
        <b className="clickable" onClick={() => scrollToRef(contactRef)}>
          Contact Us
        </b>{" "}
        or{" "}
        <b className="clickable" onClick={() => scrollToRef(setAppointmentRef)}>
          Set an Appointment
        </b>{" "}
        to learn more about our resources and engagement model.
      </p>
    </div>
  );
}
