import style from "../css/About.css";

const About = ({ contactRef, setAppointmentRef }) => {
  const scrollToRef = reff => {
    reff.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="about">
      <h1>About</h1>
      <p>
        ZScore is a Texas based software consultancy helping Fortune 1000 firms
        with teams of vetted and ready-to-go developers to support their digital
        transformation projects.
      </p>
      <p className="CTA">
        <b className="clickable" onClick={() => scrollToRef(contactRef)}>
          Contact Us
        </b>{" "}
        or{" "}
        <b className="clickable" onClick={() => scrollToRef(setAppointmentRef)}>
          Set an Appointment
        </b>{" "}
        to learn more about our resources and engagement model.
      </p>
    </div>
  );
};
export default About;
