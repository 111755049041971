import React, { useState, useEffect } from "react";
import style from "../css/Header.css";

const Header = ({ aboutRef, valuePropRef, portfolioRef, contactRef }) => {
  const scrollToRef = reff => {
    reff.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div class="menu-wrap">
      <header className="App-header">
        <div className="header-block">
          <a href="/">
            <img
              className="logo"
              style={{ display: "block" }}
              alt="logo"
              src="/Zscorelogo9.png"
            />
          </a>
          <h3 className="clickable" onClick={() => scrollToRef(aboutRef)}>
            About
          </h3>
          <h3 className="clickable" onClick={() => scrollToRef(valuePropRef)}>
            Value Prop
          </h3>
          <h3 className="clickable" onClick={() => scrollToRef(portfolioRef)}>
            Portfolio
          </h3>
          <h3 className="clickable" onClick={() => scrollToRef(contactRef)}>
            Contact
          </h3>
        </div>
      </header>
    </div>
  );
};

export default Header;
