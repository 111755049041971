import style from "../css/Contact.css";
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
const axios = require("axios");

require("dotenv").config();

const Contact = () => {
  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Please include a valid name.")
      .max(50, "Name exceeds character limit.")
      .required("Please include your name."),
    email: Yup.string()
      .email("Invalid email.")
      .required("Email is required."),
    message: Yup.string()
      .min(5, "This message is too short.")
      .max(500, "Message exceeds allowed length")
      .required("Message is required.")
  });

  const submitForm = async (values, actions, setIsSent) => {
    setTimeout(() => {
      axios.post("https://zscoremailer.vercel.app/api/sendMail", values, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      actions.setSubmitting(false);
      actions.resetForm({
        values: { name: "", email: "", title: "", company: "", message: "" }
      });
      setIsSent(true);
    }, 400);
  };

  const [captchaPassed, setCaptchaPassed] = useState(false);

  function onChange(value) {
    setCaptchaPassed(true);
  }

  window.submitForm = submitForm;
  const [isSent, setIsSent] = useState(false);
  return (
    <div>
      <h1>Contact</h1>
      <div className="wrapper">
        <Formik
          initialValues={{
            name: "",
            email: "",
            title: "",
            company: "",
            message: ""
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            submitForm(values, actions, setIsSent);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="form" id="contact-form">
              <Field type="name" placeholder="Name" name="name" />
              <ErrorMessage name="name" component="div" className="error" />
              <Field type="title" placeholder="Title" name="title" />
              <Field type="company" placeholder="Company" name="company" />
              <Field type="email" placeholder="Email" name="email" />
              <ErrorMessage name="email" component="div" className="error" />
              <Field
                type="message"
                as="textarea"
                placeholder="Message"
                name="message"
                className="form-textarea"
              />
              <ErrorMessage name="message" component="div" className="error" />
              <button
                type="submit"
                className={!captchaPassed ? "noHover" : null}
                disabled={isSubmitting || !captchaPassed}
              >
                Submit
              </button>

              {isSent ? (
                <div className="success">
                  Thank you. A representative will be in contact with you soon.
                </div>
              ) : null}
              <div className="captcha">
                <ReCAPTCHA
                  sitekey="6LeeCJ0cAAAAAOh9OdvLEtCfJw3GdNhg7gISFj-I"
                  onChange={onChange}
                  className="captcha"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Contact;
