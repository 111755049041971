import React, { useState, useEffect } from "react";

import style from "../css/Portfolio.css";

const Portfolio = () => {
  const values = [
    "Lower costs",
    "Scale rapidly",
    "Enjoy tax benefits",
    "Unlock latent value",
    "Focus on your core competencies",
    "Secure top notch talent in a fiercely competitive space"
  ];

  return (
    <div className="portfolio">
      <h1>Value Prop</h1>
      <p>
        Enterprises are engaged in a multi-year transition from legacy systems
        to cloud-based technology as a platform for changing the way they
        conduct business. The ensuing digital transformation is about unleashing
        new value for all participants in a company’s value chain. Digital
        transformation then is about transforming every facet of business from
        employee productivity to automation to data-driven decision making and
        more.
      </p>
      <p>
        Globally, expenditures related to digital transformations surpassed $1
        trillion in 2017 and are projected to increase to $6.8 trillion by 2023
        per an IDC forecast.
      </p>
      <p>
        The major impediments to these large scale transformation projects are
        corporate inertia and a scarcity of talent where and when it matters.
        ZScore solves the capacity constraint problem all major firms are
        currently facing. We bolt on experienced developers to your ongoing
        projects so that the digital transformation projects can proceed
        unhindered. Swift assignment of resources and rapid scaling up (or down)
        affords our clients the operational flexibility they need in order to
        pursue their business objectives.
      </p>

      <p>
        Partnering with ZScore for your software development needs allows you
        to:
      </p>
      <ul>
        {values.map(v => {
          return <li>{v}</li>;
        })}
      </ul>
    </div>
  );
};

export default Portfolio;
